import { api } from ":mods";
import { ENDPOINTS } from "../const";

export async function getCourses() {

      return api
		.get(ENDPOINTS.COURSES_LIST)
		.then(async (res) => {
                  const dataList  = res.data;
			if (!dataList) return Promise.reject();
			return Promise.resolve(dataList);
		})
		.catch((err) => {})

}